import Layout from "@components/layout";

import React, { useEffect, useState } from "react";
import {
  withMKBPlayers,
  withMKBCredit,
  withMKBAction,
  withMKBPlayerCredit,
} from "@helpers/hoc/withQueries";
import {
  withMutateAddCredit,
  withMutatePatchPages,
} from "@helpers/hoc/withMutations";
import { Toast } from "antd-mobile";

import SEO from "@components/common/seo";
import {
  QuestionCircleOutlined,
  SwapOutlined,
  MoneyCollectOutlined,
  DollarOutlined,
  RocketOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import "@styles/global.styles.css";
import {
  Select,
  Form,
  Button,
  DatePicker,
  Divider,
  Input,
  Tag,
  Modal,
  Spin,
  Popconfirm,
  FloatButton,
} from "antd";
import dayjs from "dayjs";
import { extractGuestData, calcCreditStatus, isBrowser } from "@helpers/tools";

type Props = {
  location?: {
    pathname: string;
  };

  mkbPlayers: {
    notionMKBPlayers: any;
  };
  creditData: any;
  creditCreate: Function;
  refetchMkbCredit: Function;
  refetchMkbPlayerCredit: Function;
  patchPages: Function;
  mkbAction: any;
  mkbCredit: any;
  mkbPlayerCredit: any;
  loadingMkbPlayers: boolean;
  loadingMkbAction: boolean;
  loadingMkbCredit: boolean;
  loadingMkbPlayers: boolean;
  loadingMkbPlayerCredit: boolean;
};

const Home: React.FC<Props> = ({
  location,
  mkbPlayers,
  refetchMkbCredit,
  refetchMkbPlayerCredit,
  mkbAction,
  mkbCredit,
  creditData,
  creditCreate,
  loadingMkbCredit,
  loadingMkbPlayers,
  loadingMkbPlayerCredit,
  mkbPlayerCredit,
  patchPages,
}) => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const [open, setOpen] = useState(false);
  const [transferOpen, setTransferOpen] = useState(false);

  const select = extractGuestData(
    mkbPlayers?.notionMKBPlayers?.data?.results
  )?.sort((a, b) => b.handicap - a.handicap);
  const selectAction = extractGuestData(mkbAction?.mkbAction?.data?.results);

  const creditHis = mkbCredit?.mkbCredit?.data?.results;

  const creditSum = mkbPlayerCredit?.mkbPlayerCredit?.data;

  const poolStatus = creditSum?.reduce((sum, player) => sum + player.total, 0);

  const status = calcCreditStatus(creditSum);
  const username = isBrowser() && localStorage.getItem("username");

  useEffect(() => {
    refetchMkbCredit();
    refetchMkbPlayerCredit();
  }, [creditData]);

  return (
    <Layout location={location.pathname}>
      <SEO title="Badminton" />
      <div>
        <Divider orientation="left">
          Players Credits <DollarOutlined />
        </Divider>
        <Spin tip="Loading" size="large" spinning={loadingMkbPlayerCredit}>
          <div className="m-2">
            {creditSum?.map((res, key) => {
              return (
                <div
                  key={key}
                  className="flex items-center w-full py-2 border-b"
                  onClick={() => {
                    form2.setFieldsValue({
                      amount: res.total,
                      player: res.id,
                      action: "c3fc33b5-3873-4dce-a1f4-40c2de1d8500",
                    });
                    setOpen(!open);
                  }}
                >
                  <span className="flex-shrink-0 text-left">
                    <Tag bordered={false}>{key + 1}.</Tag> {res.name}
                  </span>
                  <div className="flex-grow mx-2 border-b border-gray-300 border-dotted"></div>
                  <span className="flex-shrink-0 text-right">{res.total}</span>
                </div>
              );
            })}
          </div>
          <Divider orientation="left">
            Total Credit <RocketOutlined />
          </Divider>
          <div className="m-2">
            <div className="flex items-center w-full py-2 border-b">
              <span className="flex-shrink-0 text-left">
                Total Players Credit
              </span>
              <div className="flex-grow mx-2 border-b border-gray-300 border-dotted"></div>
              <span className="flex-shrink-0 text-right">
                <Tag bordered={false}>{status.totalLiable}</Tag>
              </span>
            </div>
            <div className="flex items-center w-full py-2 border-b">
              <span className="flex-shrink-0 text-left">Total Players Owe</span>
              <div className="flex-grow mx-2 border-b border-gray-300 border-dotted"></div>
              <span className="flex-shrink-0 text-right">
                <Tag bordered={false}>{status.totalUncollectedCredit}</Tag>
              </span>
            </div>
            <div className="flex items-center w-full py-2 border-b">
              <span className="flex-shrink-0 text-left">
                Latest Pool Status
              </span>
              <div className="flex-grow mx-2 border-b border-gray-300 border-dotted"></div>
              <span className="flex-shrink-0 text-right">
                <Tag bordered={false}>{-poolStatus}</Tag>
              </span>
            </div>
          </div>
        </Spin>
        <Divider orientation="left">
          Credit Histories <ProfileOutlined />
        </Divider>
        <Spin tip="Loading" size="large" spinning={loadingMkbCredit}>
          <div className="m-2">
            <div className="m-2">
              {creditHis?.map((res, key) => {
                return (
                  <div key={key}>
                    {res.properties.Date.date.start}
                    <br />
                    <div className="flex items-center w-full py-2 border-b">
                      <span className="flex-shrink-0 text-left">
                        <Tag>
                          {
                            res.properties.name.rollup.array[0].title[0]
                              .plain_text
                          }
                        </Tag>
                        <Tag bordered={false} color="geekblue">
                          (
                          {
                            res.properties.action.rollup.array[0].title[0]
                              .plain_text
                          }
                          )
                        </Tag>{" "}
                      </span>
                      <div className="flex-grow mx-2 border-b border-gray-300 border-dotted"></div>
                      <span className="flex-shrink-0 text-right">
                        {res.properties.Amount.number}{" "}
                        <Popconfirm
                          title="Delete the record"
                          description="Are you sure to delete this record?"
                          icon={
                            <QuestionCircleOutlined style={{ color: "red" }} />
                          }
                          onConfirm={() => {
                            console.log(res.id);

                            patchPages({
                              variables: { input: res.id },
                            });
                            Toast.show({
                              icon: "loading",
                              content: "Deleting credit...",
                            });
                          }}
                        >
                          <Button type="link" danger>
                            Delete
                          </Button>
                        </Popconfirm>
                      </span>
                    </div>
                  </div>
                );
              })}

              <Divider />
            </div>
          </div>
        </Spin>
      </div>
      <FloatButton.Group
        shape="circle"
        style={{
          insetBlockEnd: 80,
        }}
      >
        <FloatButton
          onClick={() => {
            setOpen(true);
          }}
          type="primary"
          icon={<MoneyCollectOutlined />}
        />
        <FloatButton
          onClick={() => {
            setTransferOpen(true);
          }}
          icon={<SwapOutlined />}
        />
      </FloatButton.Group>
      <Modal
        centered
        footer={null}
        title={
          <span className="flex items-center">
            Insert Credit <DollarOutlined className="ml-1" />{" "}
          </span>
        }
        open={open}
        onOk={() => {
          setOpen(false);
        }}
        onCancel={() => {
          setOpen(false);
        }}
        okText="Submit"
      >
        <Form
          form={form2}
          layout="vertical"
          initialValues={{ todayDate: dayjs() }}
          onFinish={values => {
            // Find the selected action to determine if the amount should be negative
            const selectedAction = selectAction.find(
              action => action.value === values.action
            );

            // Adjust the amount based on isNeg property
            const adjustedAmount = selectedAction?.isNeg
              ? -Math.abs(values.amount)
              : Math.abs(values.amount);

            // Prepare the input for the mutation
            const input = {
              ...values,
              amount: adjustedAmount, // Use the adjusted amount
            };

            creditCreate({ variables: { input, addedBy: username } });
            setOpen(false);
            form2.resetFields();
            Toast.show({
              icon: "success",
              content: "Credit Saved",
            });
          }}
        >
          <Form.Item
            label="Players"
            name="player"
            rules={[{ required: true, message: "Please input your player!" }]}
          >
            <Select
              showSearch
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              optionFilterProp="label"
              options={select}
              loading={loadingMkbPlayers}
              disabled={loadingMkbPlayers}
            />
          </Form.Item>
          <Form.Item
            label="Action"
            name="action"
            rules={[{ required: true, message: "Please input your action!" }]}
          >
            <Select
              showSearch
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              optionFilterProp="label"
              options={selectAction}
            />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="amount"
            rules={[{ required: true, message: "Please input your Amount!" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item className="my-4" name="todayDate">
            <DatePicker />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        centered
        footer={null}
        title={
          <span className="flex items-center">
            Transfer Credit <SwapOutlined className="ml-1" />{" "}
          </span>
        }
        open={transferOpen}
        onOk={() => {
          setTransferOpen(false);
        }}
        onCancel={() => {
          setTransferOpen(false);
        }}
        okText="Submit"
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ todayDate: dayjs() }}
          onFinish={values => {
            creditCreate({
              variables: {
                input: {
                  player: values.fromPlayer,
                  action: "ec152357302949e192231c0628ab44a4",
                  amount: -Math.abs(values.amount),
                  todayDate: values.todayDate,
                },
                addedBy: username,
              },
            });
            creditCreate({
              variables: {
                input: {
                  player: values.toPlayer,
                  action: "c3fc33b538734dcea1f440c2de1d8500",
                  amount: Math.abs(values.amount),
                  todayDate: values.todayDate,
                },
                addedBy: username,
              },
            });

            setTransferOpen(false);
            form.resetFields();
            Toast.show({
              icon: "loading",
              content: "Transfering credit...",
              afterClose: () => {
                Toast.show({
                  icon: "success",
                  content: "Transfer Success",
                });
              },
            });
          }}
        >
          <Form.Item
            label="From Player"
            name="fromPlayer"
            rules={[{ required: true, message: "Please select the player!" }]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={select}
              loading={loadingMkbPlayers}
              disabled={loadingMkbPlayers}
            />
          </Form.Item>
          <Form.Item
            label="To Player"
            name="toPlayer"
            rules={[{ required: true, message: "Please select the player!" }]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={select}
              loading={loadingMkbPlayers}
              disabled={loadingMkbPlayers}
            />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="amount"
            rules={[{ required: true, message: "Please input the amount!" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item className="my-4" name="todayDate">
            <DatePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default withMutatePatchPages(
  withMKBPlayerCredit(
    withMutateAddCredit(withMKBCredit(withMKBAction(withMKBPlayers(Home))))
  )
);
